:root{
  --light-blue : #04C3FF;
  --light-blue-shadow:  rgb(69 211 255 / 27%);
  --sea-green: #0ABEAD;
  --sea-green-dark: #0CBFAE;
  --sea-green-extra-dark: #09BEAD;
  --sea-green-shadow: rgba(11, 190, 173,0.4);
  --header-hamburger-background : rgba(11, 190, 173,0.8);
  --orange: #F57F17;
  --dark-orange: #FF5823;
  --orange-shadow: rgba(245, 127, 23,0.2);
  --white: #ffffff;
  --black: #333333;
  --disabled-btn: #09bead7a;
  --error-message:  #e44f4fd1;
  --toast-error: #e44f4ffa;
  --toast-shadow:  #9c9c9ce3;
  --toast-success: #0CBFAE;
  --loader-color: #0CBFAE
}


/* CSS */
.backtohome {
  appearance: none;
  background-color: #2ea44f;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.backtohome:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.backtohome:hover {
  background-color: #2c974b;
  color: white;
}

.backtohome:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
}

.backtohome:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.backtohome:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}