h1 {
    text-align: center;
    color: #007BFF;
    font-size: 28px;
    margin-bottom: 20px;
}

.project-info {
    border-top: 2px solid #007BFF;
    border-bottom: 2px solid #007BFF;
    padding: 20px 0;
    margin: 20px 0;
    background-color: #ff562323;
}

.project-info h2 {
    margin: 0;
    padding: 0;
    font-size: 24px;
    color: #333;
}

.project-info p {
    margin: 0;
    padding: 0;
    color: #000000!important;
}

.project-info a {
    text-decoration: none;
    color: #080808!important;
}

.duration {
    float: right;
    color: #007BFF;
}

.work-list {
    /* border-top: 2px solid #0a0a0a; */
    padding-top: 20px;
    margin-top: 20px;
    background-color: #f9f9f9;
}

ul {
    list-style-type: square;
    
}

li {
    margin: 5px 0;
    color: #555;
}

.work-list h2 {
    color: #ff5823;
}