.about-me-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0 0 0;
}
.about-me-parent {
  max-width: 1000px;
  width: 70%;
}
.about-me-card {
  display: flex;
  width: 100%;
  box-shadow: 0 0px 20px -2px #1f2235;
}
.about-me-profile {
  width: 50%;
  /* background-image: url("../../assets/AboutMe/me.jpg"); */
  background-image: url("../../assets/AboutMe/me2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.about-me-details {
  width: 48%;
  text-align: justify;
  padding: 30px;
  line-height:normal;
}
.about-me-description {
  font-size: 14px;
}
.about-me-hignlights {
  margin: 80px 0;
}
.highlight-heading {
  font-family: "Poppins Bold";
  font-weight: bold;
  font-size: 15px;
  margin: 0 0 5px 0;
}
.highlight {
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-size: 13px;
}
.highlight-blob {
  height: 12px;
  width: 12px;
  margin: 0 10px 0 0;
  background-color: #ff5823;
  border-radius: 50%;
}
.about-me-options .highlighted-btn {
  margin: 0 0 0 30px;
}

/* by kh */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 10px;
  border-radius: 10px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.blog-button{
    padding: 10px 0;
}

/*IPAD PRO RESPONSIVENESS*/
@media only screen and (max-width: 1110px) {
  .about-me-parent {
    width: 88%;
  }
}
@media only screen and (max-width: 898px) {
  .about-me-profile {
    display: none;
  }
  .about-me-details {
    width: 100%;
  }
}
@media only screen and (max-width: 466px) {
  .about-me-options {
    display: flex;
    flex-direction: column;
  }
  .about-me-options .highlighted-btn {
    margin: 24px 0 0 0 !important;
  }
  .about-me-options button {
    width: 100%;
  }
}
